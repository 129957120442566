import { type ReactNode, useEffect, useRef } from 'react'

import { useRecordContext } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import Icons from 'assets/icons'
import {
    ArrayControllerContextProvider,
    ArrayControllerElements,
    Gallery,
    GalleryItemGrid,
    type GalleryProps,
    useArrayControllerContext,
    useUtilityDrawerContext,
} from 'components'
import GalleryItem, { type RawGalleryItemProps } from 'components/Gallery/GalleryItem'
import { useNotify } from 'core'
import {
    Button,
    Alert,
    Tooltip,
    Stack,
    InfoBadge,
    NonDisplayedInput,
    Typography,
    BoxContainer,
} from 'ui'

import { attachmentsIndexes, maxFileSize, photoName } from '../utils'

const MAX_PHOTO_COUNT = 10

interface PhotosContentProps extends Pick<GalleryProps, 'onGalleryClose' | 'onGalleryStart'> {
    add: (index: number, file: string | File) => void
    remove: (index: number) => void
    count: number
    content: ReactNode
    disabled?: boolean
    TooltipOnDisabled?: string
}

interface PhotosProps
    extends Pick<
        PhotosContentProps,
        'onGalleryClose' | 'onGalleryStart' | 'disabled' | 'TooltipOnDisabled'
    > {}
export const PhotosInput = (props: PhotosProps) => {
    const { getValues } = useFormContext()

    return (
        <ArrayControllerContextProvider
            initialArray={() => attachmentsIndexes.filter((index) => getValues(photoName(index)))}
        >
            <UpdateList />
            <ExtendedJobPhotosContent {...props} />
        </ArrayControllerContextProvider>
    )
}

const UpdateList = () => {
    const record = useRecordContext()

    if (!record) {
        return null
    }

    return <UpdateListBase />
}

// if there is cached data, update the list
const UpdateListBase = () => {
    const record = useRecordContext()
    const arrayController = useArrayControllerContext()

    useEffect(
        () => {
            arrayController.setArray(() =>
                attachmentsIndexes.filter((index) => record[photoName(index)]),
            )
        },
        attachmentsIndexes.map((index) => record[photoName(index)]),
    )

    return null
}

interface PhotoProps extends Omit<RawGalleryItemProps, 'src'> {
    index: number
}
const FormJobPhoto = (props: PhotoProps) => {
    const source = photoName(props.index)
    const file: string | File = useWatch({ name: source })
    if (!file) {
        return null
    }
    return (
        <GalleryItem
            file={file}
            {...props}
        />
    )
}

const PhotosContent = ({
    remove,
    add,
    count,
    content,
    disabled: disabledProp,
    TooltipOnDisabled,
    ...rest
}: PhotosContentProps) => {
    let finalContent = <Alert severity="info">No Photos Added</Alert>
    const galleryOpener = useRef<{ open: (i: number) => void }>(null)

    const { extra }: { extra: { disabledFields: boolean } } = useUtilityDrawerContext()

    const disabled = disabledProp || extra?.disabledFields

    const notify = useNotify()
    const upload = async (event, index?: number) => {
        const file: File = event.target.files?.[0]
        if (!file) {
            return
        }

        const type = file.type
        if (!(type.startsWith('image') && !type.includes('svg'))) {
            notify({
                title: 'Invalid file format',
                type: 'error',
            })
        } else if (file.size > maxFileSize.size) {
            notify({
                title: maxFileSize.errorMessage,
                type: 'error',
            })
        } else {
            add(index, file)
        }
        event.target.value = null
    }

    if (count) {
        finalContent = (
            <Gallery
                {...rest}
                getOpener={(opener) => {
                    galleryOpener.current = opener
                }}
                onImageDelete={
                    disabled
                        ? null
                        : (galleryCtx) => {
                              const item = galleryCtx.getItemData(galleryCtx.currIndex)
                              remove(Number(item.element.id))
                          }
                }
            >
                {content}
            </Gallery>
        )
    }

    return (
        <Stack
            spacing="14px"
            mt="10px"
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    color="text.secondary"
                    variant="chartTitle"
                    component={BoxContainer}
                    gap="8px"
                >
                    Photos <InfoBadge badgeContent={count} />
                </Typography>
                <BoxContainer gap="17px">
                    <Tooltip
                        title={
                            disabled
                                ? TooltipOnDisabled
                                : count >= MAX_PHOTO_COUNT
                                  ? 'Maximum image limit (10) reached'
                                  : 'Add up to 10 images, max size 30 MB each'
                        }
                    >
                        <label>
                            <NonDisplayedInput
                                type="file"
                                disabled={count >= MAX_PHOTO_COUNT || disabled}
                                onChange={upload}
                                accept="image/*"
                            />
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                component="div"
                                disabled={count >= MAX_PHOTO_COUNT || disabled}
                                startIcon={<Icons.UploadFile />}
                            >
                                UPLOAD FILE
                            </Button>
                        </label>
                    </Tooltip>
                </BoxContainer>
            </BoxContainer>
            {finalContent}
        </Stack>
    )
}

const ExtendedJobPhotosContent = (props: PhotosProps) => {
    const form = useFormContext()
    const arrayController = useArrayControllerContext()

    const setFile = (index: number, file: File) => {
        form.setValue(photoName(index), file, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        })
    }
    const addFile = (index: number, file: File) => {
        let freeItem = index
        if (typeof index === 'undefined') {
            freeItem = arrayController.append()
        }
        setFile(freeItem, file)
    }
    const removeFile = (index: number) => {
        setFile(index, null)
        arrayController.remove({ item: index, index })
    }
    return (
        <PhotosContent
            {...props}
            count={arrayController.array.length}
            content={
                <GalleryItemGrid
                    gridHeight="78px"
                    gap="12px"
                >
                    <ArrayControllerElements onDelete={({ item }) => removeFile(item)}>
                        {({ item }) => {
                            return (
                                <FormJobPhoto
                                    id={String(item)}
                                    index={item}
                                    key={item}
                                    sx={{
                                        height: '78px',
                                    }}
                                />
                            )
                        }}
                    </ArrayControllerElements>
                </GalleryItemGrid>
            }
            add={addFile}
            remove={removeFile}
        />
    )
}
